<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h3 class="card-label font-weight-bolder">{{ $t('NotificationSettings.Settings') }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="card card-custom">
          <div class="card-body">
            <!--begin::Item-->
            <div class="d-flex align-items-center bg-light-light rounded p-5 gutter-b">
              <font-awesome-icon icon="user-edit" class="icon-md mr-2"/>
              <div class="d-flex flex-column flex-grow-1 mr-2 mt-1">
                <router-link
                    to="/"
                    v-slot="{ href, route, navigate, isActive, isExactActive }"
                >
                  <a :href="href" @click="navigate"
                     class="font-weight-bold text-primary text-hover-primary font-size-lg">{{
                      $t('NotificationSettings.Profile')
                    }}</a>
                </router-link>
              </div>
            </div>
            <!--end::Item-->
            <!--begin::Item-->
            <div class="d-flex align-items-center bg-light-light rounded p-5 gutter-b">
              <font-awesome-icon icon="bell" class="icon-md mr-2"/>
              <div class="d-flex flex-column flex-grow-1 mr-2 mt-1">
                <router-link
                    to="/notification-settings"
                    v-slot="{ href, route, navigate, isActive, isExactActive }"
                >
                  <a :href="href" @click="navigate"
                     class="font-weight-normal text-dark-75 text-hover-primary font-size-lg">{{
                      $t('NotificationSettings.Notifications')
                    }}</a>
                </router-link>
              </div>
            </div>
            <!--end::Item-->
          </div>
        </div>
      </div>
      <div class="col-9">
        <div v-if="successAlert" class="alert alert-custom alert-success fade show" role="alert">
          <div class="alert-text">{{ $t('NotificationSettings.profile-success') }}</div>
          <div class="alert-close">
            <button type="button" class="close" data-dismiss="alert" @click="successAlert = false" aria-label="Close">
              <span aria-hidden="true"><i class="ki ki-close"></i></span>
            </button>
          </div>
        </div>
        <div v-if="errorAlert" class="alert alert-custom alert-danger fade show" role="alert">
          <div class="alert-text">{{ errorAlert }}</div>
          <div class="alert-close">
            <button type="button" class="close" data-dismiss="alert" @click="errorAlert = null" aria-label="Close">
              <span aria-hidden="true"><i class="ki ki-close"></i></span>
            </button>
          </div>
        </div>
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label font-weight-bolder">{{ $t('NotificationSettings.Profile') }}</h3>
            </div>
          </div>
          <div v-if="!loaded" class="container py-20">
            <PuSkeleton width="100%"></PuSkeleton>
          </div>
          <div v-if="loaded" class="card-body">
            <div class="row">
              <div class="col-4 text-center" style="border-right: 1px solid gray">
                <div class="image-input image-input-outline image-input-circle">
                  <div v-if="identity.avatar && !identity.avatar.is_default" class="image-input-wrapper w-150px h-150px" :style="'background-image:url(' + identity.avatar.url + ')'"></div>
                  <div v-else class="image-input-wrapper w-150px h-150px font-size-h3 symbol-label font-weight-boldest pt-15">{{ firstLettersFio }}</div>
                  <label v-if="selfChangePersonalData"
                         class="btn btn-sm btn-icon btn-circle btn-success btn-hover-text-primary btn-shadow"
                         data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
                    <i class="ki ki-plus icon-md"></i>
                    <input type="file"
                           name="profile_avatar"
                           @change="onFileChange($event)"
                           accept=".png, .jpg, .jpeg, .svg"/>
                    <input type="hidden" name="profile_avatar_remove"/>
                  </label>
                  <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title="Cancel avatar">
                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                   </span>
                  <span
                      v-if="selfChangePersonalData && identity.avatar && !identity.avatar.is_default"
                      class="btn btn-sm btn-icon btn-circle btn-danger btn-hover-text-primary btn-shadow"
                      data-action="remove"
                      data-toggle="tooltip"
                      @click="onFileDelete">
                    <i class="ki ki-bold-close icon-sm text-white"></i>
                  </span>
                </div>
                <template v-if="isEdit">
                  <div class="form-group text-left mt-5">
                    <label class="text-muted">{{ $t('NotificationSettings.Last_Name') }}:</label>
                    <input type="text"
                           :required="registration.last_name.require"
                           :disabled="processing"
                           v-model="profileData.last_name"
                           class="form-control form-control-solid" placeholder=""/>
                  </div>
                  <div class="form-group text-left">
                    <label class="text-muted">{{ $t('NotificationSettings.First_Name') }}:</label>
                    <input type="text"
                           :required="registration.first_name.require"
                           :disabled="processing"
                           v-model="profileData.first_name"
                           class="form-control form-control-solid" placeholder=""/>
                  </div>
                  <div class="form-group text-left">
                    <label class="text-muted">{{ $t('NotificationSettings.Father_Name') }}:</label>
                    <input type="text"
                           :disabled="processing"
                           v-model="profileData.father_name"
                           class="form-control form-control-solid" placeholder=""/>
                  </div>
                </template>
                <template v-else>
                  <h2 class="mt-10 font-weight-boldest">{{ profileData.fio }}</h2>
                </template>
              </div>
              <div class="col-8 col-pull-8">
                <form v-if="!isChangePassword" class="form">
                  <div class="form-group">
                    <label class="text-muted">{{ $t('NotificationSettings.Birthday') }}:</label>
                    <template v-if="isEdit">
                      <input type="text"
                             :disabled="processing"
                             v-mask="'##.##.####'"
                             v-model="profileData.birthday"
                             class="form-control form-control-solid" placeholder=""/>
                      <span v-if="profileData.birthday && (profileData.birthday.length !== 10 || !isValidBirthday)"
                            class="form-text text-danger">{{ $t('NotificationSettings.validation-error') }}</span>
                    </template>
                    <template v-else>
                      <p class="font-weight-boldest">{{ profileData.birthday }}</p>
                    </template>
                  </div>
                  <div class="form-group">
                    <label class="text-muted">{{ $t('NotificationSettings.Email') }}:</label>
                    <template v-if="isEdit">
                      <input type="email"
                             :required="registration.email.require"
                             :disabled="processing"
                             @input="onChangeEmail"
                             v-model="profileData.email"
                             class="form-control form-control-solid" placeholder=""/>
                      <span v-if="!validateEmail(profileData.email)"
                            class="form-text text-danger">{{ $t('NotificationSettings.validation-error') }}</span>
                    </template>
                    <template v-else>
                      <p class="font-weight-boldest">{{ profileData.email }}</p>
                    </template>
                  </div>
                  <div class="form-group">
                    <label class="text-muted">{{ $t('NotificationSettings.Phone') }}:</label>
                    <template v-if="isEdit">
<!--                      <input type="text"-->
<!--                             :required="registration.phone.require"-->
<!--                             :disabled="processing"-->
<!--                             v-mask="'+# ### ###-##-##'"-->
<!--                             @input="onChangePhone"-->
<!--                             v-model="profileData.phone"-->
<!--                             class="form-control form-control-solid" placeholder=""/>-->
                      <vue-tel-input
                          ref="phoneNumber"
                          class="form-control"
                          :required="registration.phone.require"
                          v-model="profileData.phone"
                          :disabled="processing"
                          v-bind="bindProps"
                          @focus="onFocus"
                          v-on:validate="checkPhoneValid"></vue-tel-input>
                      <span v-if="!profileData.phone || !isPhoneValid"
                            class="form-text text-danger">{{ $t('NotificationSettings.validation-error') }}</span>
                    </template>
                    <template v-else>
                      <p class="font-weight-boldest">{{ profileData.phone }}</p>
                    </template>
                  </div>
                  <div v-if="selfChangePersonalData" class="card-footer text-center">
                    <button v-if="!isEdit"
                            class="btn btn-outline-primary mr-2"
                            @click="onChangePassword($event)">
                      {{ $t('NotificationSettings.Change_Password') }}</button>
                    <button class="btn btn-primary mr-2"
                            :disabled="processing || isEdit ? !isValid : null
                            || !profileData.email_confirmed || !profileData.phone_confirm"
                            @click="onEditProfile($event)">
                      {{ isEdit ? $t('NotificationSettings.Save') : $t('NotificationSettings.Edit') }}</button>
                    <button v-if="!profileData.email_confirmed && !isVerifyAccountSent"
                            class="btn btn-outline-primary"
                            @click="onVerifyAccount($event)">
                      {{ $t('NotificationSettings.Verify_Account') }}</button>
                    <button v-if="isEdit"
                            class="btn btn-secondary"
                            @click="onCancel($event)">
                      {{ $t('NotificationSettings.Cancel') }}</button>
                    <div class="mt-5">
                      <p v-if="!profileData.email_confirmed && !isVerifyAccountSent" class="text-warning">{{ $t('NotificationSettings.email-warning') }}</p>
                      <p v-if="isVerifyAccountSent" class="text-success">{{ $t('NotificationSettings.Verify_Account_Sent') }}</p>
                      <p v-if="!profileData.phone_confirm" class="text-warning">{{ $t('NotificationSettings.phone-warning') }}</p>
                    </div>
                  </div>
                </form>
                <form v-if="isChangePassword" class="form">
                  <div class="form-group">
                    <label class="text-muted">{{ $t('NotificationSettings.Password') }}:</label>
                    <input type="password"
                           :disabled="processing"
                           v-model="passwordData.password" class="form-control form-control-solid" placeholder=""/>
                  </div>
                  <div class="form-group">
                    <label class="text-muted">{{ $t('NotificationSettings.New_Password') }}:</label>
                    <input type="password"
                           :disabled="processing"
                           v-model="passwordData.password_new" class="form-control form-control-solid" placeholder=""/>
                    <span class="form-text text-muted">{{ $t('NotificationSettings.validation-password-notice') }}</span>
                  </div>
                  <div class="form-group">
                    <label class="text-muted">{{ $t('NotificationSettings.Confirm_New_Password') }}:</label>
                    <input type="password"
                           :disabled="processing"
                           v-model="passwordData.password_re" class="form-control form-control-solid" placeholder=""/>
                    <span v-if="passwordData.password_re && passwordData.password_new !== passwordData.password_re"
                          class="form-text text-danger">{{ $t('NotificationSettings.validation-password-error') }}</span>
                  </div>
                  <div class="card-footer text-center">
                    <button :disabled="processing || !isNewPasswordValid" class="btn btn-primary mr-2" @click="onSavePassword($event)">{{ $t('NotificationSettings.Save') }}</button>
                    <button class="btn btn-secondary" @click="onCancel($event)">{{ $t('NotificationSettings.Cancel') }}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import api from '@/plugins/api'

export default {
  components: {},
  data() {
    return {
      loaded: true,
      processing: false,
      isEdit: false,
      isChangePassword: false,
      isVerifyAccountSent: false,
      // isChangeEmail: false,
      // isChangePhone: false,
      bindProps: {
        mode: "international",
        // validCharactersOnly: true,
        // defaultCountry: "RU",
        preferredCountries: ["RU", "KZ", "IL"],
        inputOptions: {
          showDialCode: true,
          autofocus: true
        }
      },
      isPhoneValid: false,
      successAlert: false,
      errorAlert: null,
      profileData: {
        first_name: '',
        last_name: '',
        birthday: '',
        email: '',
        phone: ''
      },
      currentEmail: '',
      currentPhone: '',
      passwordData: {
        password: '',
        password_new: '',
        password_re: ''
      },
    }
  },
  mounted() {
    this.fetch('clear')
  },
  computed: {
    ...mapGetters({
      registration: 'main/registration',
      selfChangePersonalData: 'main/selfChangePersonalData',
      identity: 'user/identity',
      firstLettersFio: 'user/firstLettersFio',
    }),
    isValid() {
      if (this.profileData.first_name.length > 0 && this.profileData.last_name.length > 0
          // && this.profileData.birthday && this.profileData.birthday.length === 10
          && this.validateEmail(this.profileData.email)
          && this.profileData.phone.length === 16) return true;
      else return false;
    },
    isValidBirthday() {
      return moment(this.profileData.birthday, 'DD.MM.YYYY').isValid();
    },
    isNewPasswordValid() {
      if (this.passwordData.password_new.length >= 8
          && this.passwordData.password_new === this.passwordData.password_re) return true;
      else return false;
    },
  },
  watch: {
    identity(newVal, oldVal) {
      if (newVal !== oldVal) this.profileData = newVal;
      this.profileData.birthday = moment(this.profileData.birthday, 'YYYY-MM-DD').format('DD.MM.YYYY');
      if (this.profileData.birthday === 'Invalid date') this.profileData.birthday = null;
    }
  },
  methods: {
    onFocus() {
      const input = this.$refs.phoneNumber.$el.querySelector('input');
      const end = input.value.length;
      this.$nextTick(() => {
        input.setSelectionRange(end, end);
        input.focus();
      })
    },
    validateEmail(email) {
      let re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    fetch(clear) {
      let self = this
      let access_token = this.$store.getters['user/token'];
      self.loaded = false
      api.get('/v2/users/check', {expand: 'main_spaces,phones'}, {bearer: access_token}).then(res => {
        // this.$store.commit('user/CurrentToken', access_token);
        this.$store.commit('user/UpdateIdentity', res.data);
        self.loaded = true;
        !clear ? self.successAlert = true : null;
        // this.isChangeEmail = false;
        // this.isChangePhone = false;
        this.currentEmail = this.identity.email;
        this.currentPhone = this.identity.phone;
      });
    },
    onChangeEmail() {
      // if (this.profileData.email !== this.currentEmail) this.isChangeEmail = true;
      // else this.isChangeEmail = false;
    },
    onChangePhone() {
      // if (this.profileData.phone !== this.currentPhone) this.isChangePhone = true;
      // else this.isChangePhone = false;
    },
    checkPhoneValid(phoneObject) {
      this.isPhoneValid = phoneObject.isValid;
    },
    onFileChange(event) {
      let data = new FormData();
      let file = event.target.files[0];
      data.append('type', 'image');
      data.append('images', file);
      let config = {
        header: {
          'Content-Type' : 'multipart/form-data'
        }
      }
      api.post('/v2/users/image', data, config).then(() => {
        this.fetch();
      });
    },
    onFileDelete() {
      api.delete('/v2/users/image', {'type': 'image'}).then(() => {
        this.fetch();
      });
    },
    onChangePassword(event) {
      event.preventDefault();
      this.isChangePassword = !this.isChangePassword;
    },
    onVerifyAccount(event) {
      event.preventDefault();
      this.loaded = false;
      api.post('/v2/users/get-confirm-email').then(() => {
        this.isVerifyAccountSent = true;
        this.loaded = true;
      });
    },
    onSavePassword(event) {
      event.preventDefault();
      this.processing = true;
      api.put('/v2/users/password', {
        password: this.passwordData.password,
        password_new: this.passwordData.password_new,
        password_re: this.passwordData.password_re
      }).then(() => {
        this.fetch();
        this.isChangePassword = false;
        this.errorAlert = null;
        this.processing = false;
      }).catch(error => {
        let _error = []
        try {
          if (error.response.data.error && error.response.data.error.extra) {
            if (error.response.data.error.extra.password) {
              _error.push(this.$t('NotificationSettings.profile-error'));
            } else {
              for (let i in error.response.data.error.extra) {
                _error.push(error.response.data.error.extra[i][0])
              }
            }
          }
        } catch (e) {}
        this.errorAlert = _error.join('<br>');
        this.processing = false;
      })
    },
    onEditProfile(event) {
      event.preventDefault();
      if (this.isEdit) {
        this.processing = true;
        api.patch('/v2/users/update', {
          first_name: this.profileData.first_name,
          last_name: this.profileData.last_name,
          father_name: this.profileData.father_name,
          birthday: this.profileData.birthday ? moment(this.profileData.birthday, 'DD.MM.YYYY').format('YYYY-MM-DD') : null,
          phone: this.profileData.phone,
          // phone_confirm: this.isChangePhone ? false : true,
          // email_confirmed : this.isChangeEmail ? 0 : 1
        }).then(() => {
          this.fetch();
          this.isEdit = !this.isEdit;
          this.errorAlert = null;
          this.processing = false;
        }).catch(error => {
          let _error = []
          try {
            if (error.response.data.error && error.response.data.error.extra) {
              if (error.response.data.error.extra.birthday) {
                return;
              } else {
                for (let i in error.response.data.error.extra) {
                  _error.push(error.response.data.error.extra[i][0])
                }
              }
            }
          } catch (e) {}
          this.errorAlert = _error.join('<br>');
          this.processing = false;
        })
      } else {
        this.isEdit = !this.isEdit;
      }
    },
    onCancel(event) {
      event.preventDefault();
      this.isEdit = false;
      this.isChangePassword = false;
      this.fetch('clear');
    }
  }
}
</script>

<style>

</style>
